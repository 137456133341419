import React from 'react';
import {useState} from 'react';
import {Footer} from "./footer";
import { Header } from './header';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { LoginUrl, gmailLogin } from './serverurl';
import { useNavigate  } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import { Helmet } from 'react-helmet-async';




function SignUp_(){

        // active button
        const hidden = {
            visibility: 'hidden'
        };
    
        const visible = {
            visibility: 'visible'
        };
    

    const [button_active, set_button_active] = useState(visible);
    const [button_effect, set_button_effect] = useState(hidden);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [disabled_status, set_disabled_status] = useState("none");
    const responseMessage = (response) => {
        const credentialData =jwtDecode(response.credential);
        console.log(response);
        const data = {
            email: credentialData.email,
            name: credentialData.name
        }

        axios.post(gmailLogin, data).then((response)=>{
            console.log(response);
        }).catch((error)=>{
            console.log(error);
        })
    };
    const errorMessage = (error) => {
        console.log(error);
    };
    const navigate = useNavigate();
    
           
            let login_form = (e)=>{
                e.preventDefault();

                // button effects
                set_button_active(hidden);
                set_button_effect(visible);

                set_disabled_status("disabled");
                const data = {
                    email : email,
                    password: password
                }

             
        //  ajax request
        axios.post(LoginUrl, data).then((response)=>{
            // console.log(response);

            set_button_active(visible);
            set_button_effect(hidden);

            if(response.status !== 200){
                alert("Please try again....");
                set_disabled_status("none");

            }
            if(response.status === 200){

                if(response.data.serverstatus !== 401){

                    console.log(response.data);

                    const user_id_cookies = new Cookies(null, {path:'/'});
                    const auth_key_cookies= new Cookies(null, {path:'/'});
                    const api_key_cookies = new Cookies(null, {path:'/'});
                    const auth_refresh_key = new Cookies(null, {path:'/'});
                    const surname_cookies =  new Cookies(null, {path:'/'});
                    const name_cookies = new Cookies(null, {path:'/'});
                    const email_cookies = new Cookies(null, {path:'/'});
                    const autheticated = new Cookies(null, {path:'/'});

                    user_id_cookies.set('user_id', response.data.user_id);
                    auth_key_cookies.set('auth_key', response.data.user_id);
                    api_key_cookies.set('api_key', response.data.user_id);
                    auth_refresh_key.set('auth_refresh_key', response.data.user_id);
                    surname_cookies.set('surname', response.data.surname);
                    name_cookies.set('name', response.data.name);
                    email_cookies.set('email', response.data.email);
                    autheticated.set('autheticated', true);
                    set_button_active(visible);
                    set_button_effect(hidden);
        
                    navigate("/cbt_creator/dashboard");
                }else{
                    // restrict user from login
                    alert(response.data.message);
                    set_disabled_status("none");


                    set_button_active(visible);
                    set_button_effect(hidden);
        
                }
                
            }
            
            
        }).catch((error)=>{ 
            alert(error);
            console.log(error);
            set_disabled_status("none");

            set_button_active(visible);
            set_button_effect(hidden);


         });
        
        }    
    


   
    return(
        <>
        <Helmet>
        <meta id="description" name="discription" content="cbtdraft sign up" />
        <title id="title">cbtdraft.com | sign up</title>
      </Helmet>
        <Header />
               
                <div className="container default-margin-top" >
                    {/*  */}
                    <div className="card mx-auto static card-size" style={{padding: '20px'}}>
                 <h3 className="text-center"><a className="nav-link" href="https://cbtdraft.com" ><b>Create Account</b></a></h3>
            <p className="text-justify">
                <b>create account:</b> create account on cbtdraft by using your gmail account to continue </p>
                 <div className="form-group w-75 mt-1 mx-auto">
            
            <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
        </div>
                 

                   <div className="form-group mt-1">
                     <h6 style={{marginLeft: '40px', display:'flex', flexWrap: 'wrap', flexDirection: 'row', alignItems:'center'}}><a className="nav-link" href="/login"><b>Login</ b></ a> <a className="nav-link" href="/password-reset"><b>Password reset</b></a></ h6>
              
                   </div>
                </div>
                    {/*  */}
                </div>
                
            
      
            
<div className="footer card" >
    <Footer />
</div>

        </>

    );
}




export  default SignUp_;


import cookie from "universal-cookie";
import {Outlet, Navigate} from "react-router-dom";


const PrivateRoute = ()=>{
    const cookies = new cookie();
      const auth = {
        'autheticated' : cookies.get("autheticated")
      }
  
  
      return(
        auth.autheticated ? <Outlet /> : < Navigate to="/login" />
      )
  }

  export {PrivateRoute}
import cookie from "universal-cookie";
const cookies = new cookie();
const ajaxconfig ={
    // getting user data from cookies 
    headers:{
        'auth_key' : cookies.get("auth_key"),
'auth_refresh_key' : cookies.get("auth_refresh_key"),
'api_key' : cookies.get("api_key"),
'email' : cookies.get("email"),
'user_id' : cookies.get("user_id"),
'Access-Control-Allow-Origin': '*',
    }
}


const ajaxrequest ={
    // getting user data from cookies 
    headers:{
        'Access-Control-Allow-Origin': '*',
    }
}

export {ajaxconfig, ajaxrequest}
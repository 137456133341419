import React from "react";
import {useState, useEffect, useRef, useReducer, useContext, createContext} from "react";
import axios from "axios";
import TopMenu from "./components/top_menu";
import SideMenu from "./components/side_menu";
import DashboardWidget from './components/dashboard_widget';
import { ajaxconfig } from "../ajaxconfig";
import { useNavigate  } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import cookie from "universal-cookie";

// custom css


import { custom_side_open, custom_side_close} from "../customcss";

// custom css end
import QRCode from "react-qr-code";
import {VerifyPublicCbtPassCode, VerifyPublicGetCbtPassCode, 
    PublicMassCbtQuestionGet, CbtPermitVerification, TakeCbtPermitVerification, CbtTestData, StudentAnswerSubmit, StudentGradeSubmit} from "../serverurl";
import { QueryErrorResetBoundary } from "@tanstack/react-query";

const CbtPassCode = ()=>{
    const cookies = new cookie();
    //url parameter
    const url_param = new URLSearchParams(window.location.search);

    const get_cbt_passcode_url = url_param.get("cbt_pass_code");

    const [cbtpasscode, setcbtpasscode] = useState(get_cbt_passcode_url);
    const [test_id, set_test_id] = useState(cookies.get('email'));
    const navigate = useNavigate();

    // verify the cbt pass code 

   


    const verify_cbt_data_pass_code = (e)=>{
     
        e.preventDefault();
        const cbt_verify_data = {
            cbt_data_id : cbtpasscode
        }

        axios.post(VerifyPublicCbtPassCode, cbt_verify_data, ajaxconfig).then((response)=>{
           

            if(response.data.serverstatus === 200){
                navigate("/cbt_user/verification?cbt_pass_code="+cbtpasscode+'&test_id='+test_id);
            }
            toast(response.data.message);
        }).catch((error)=>{

            toast(error);
            console.log(error);
        });
    }
 return (

    <>

        <d-section className="main-container">
        <ToastContainer />
    <main className="main-close" id="main" >
        <div className="header-container" style={{width: '100%'}} >
            <TopMenu />
        </div>

        {/* verify cbt pass code start */ }
        <div className="card card-size mx-auto" style={{marginTop: '7%'}}>
            <form className="w-75" onSubmit={verify_cbt_data_pass_code}>
                <div class="form-group mt-2">
                    <h2 class="text-center">CBT PASS CODE </h2>
                </div>
                <div className="form-group mt-2">
                        <input value={cbtpasscode} onChange={(e)=>setcbtpasscode(e.target.value)}  placeholder="Enter CBT PASS CODE" />
                </div>
                <div className="form-group mt-2">
                        <input value={test_id} onChange={(e)=>set_test_id(e.target.value)}  placeholder="Enter CBT TEST ID" />
                </div>
                <div className="form-group mt-2 mb-5">
                    <button className="btn btn-danger w-100">
                       Verify
                    </button>
                </div>   
            </form>
        </div>
        {/* verify cbt pass code end */}

        <div className="custom-card mt-2">
            <table className="table">
                <tr>
                    <th>#</th>
                    <th>Data</th>
                    <th>Action</th>
                </tr>
                <tbody>
                    
                </tbody>

            </table>
        </div>
      </main>
</d-section>    
        </>
 )

}

const CbtPassCodeVerification = () => {


    const [cbt_pass_code_verify_info, set_cbt_pass_code_verify_info] = useState("");
    const [cbt_data, set_cbt_data] = useState([]);
    const cookies = new cookie();
    
    const [cbt_pass_code_info, set_cbt_pass_code_info] = useState(cookies.get("name"));

    //url parameter
    const url_param = new URLSearchParams(window.location.search);

    const cbt_passcode = url_param.get("cbt_pass_code");
    const test_id = url_param.get("test_id");
    const [cbt_test_id_verify, set_cbt_test_id_verify] = useState(test_id);
    const navigate = new useNavigate();
    const cbtbarcode = "https://cbtdraft.com/cbt_user/cbt_pass_code?cbt_pass_code=" + cbt_passcode;

    const cbt_permit_code_verification = (e)=>{

        e.preventDefault();

        // verifies cbt data id
        const data = {
            cbt_permit_pass_code: cbt_test_id_verify
        }
        toast(CbtPermitVerification);
        axios.post(CbtPermitVerification, data, ajaxconfig).then((response)=>{
            toast(response.data.message);
        }).catch((error)=>{
            toast(error);
            console.log(error);
        });
        
        // navigate("/cbt/cbt_question?cbt_pass_code="+cbt_passcode+"&cbt_permit_pass_code="+cbt_pass_code_verify);
        
    }


    
        const cbt_permit_code_manual_verification = (e)=>{
    
            e.preventDefault();
    
            // verifies cbt data id
            const data = {
                cbt_test_id: cbt_test_id_verify,
                cbt_test_id_info: cbt_pass_code_info,
                cbt_pass_code: cbt_passcode
            }
            axios.post(TakeCbtPermitVerification, data, ajaxconfig).then((response)=>{
                toast(response.data.message);

                if(response.data.serverstatus === 200){
                    navigate("/cbt_user/cbt_question?cbt_pass_code="+cbt_passcode+"&cbt_test_id="+cbt_test_id_verify);

                }
            }).catch((error)=>{
                toast(error);
                console.log(error);
            });
            
            
        }



        
    useEffect(()=>{

        // verify cbt pass code 
        const url = VerifyPublicGetCbtPassCode + cbt_passcode; 
        axios.get(url, ajaxconfig).then((response)=>{
            set_cbt_data(response.data.data);
            console.log(response);
            toast(response.data.message);
            if(response.data.serverstatus !== 200){
               toast("invalid cbt pass code");
            }

        }).catch((error)=>{
            toast(error);
            console.log(error);
        })

    }, []);
    return (

        <>

            <d-section className="main-container">
        <main>
        <ToastContainer />
            <div className="header-container w-100" >
    
             <TopMenu />
            </div>
     
               {/* verify cbt pass code start */}
               <div className="custom-card d-flex flex-wrap" style={{marginTop: '7%'}}>

            <table className="table w-75 mt-3 mb-3">
                <tr>
                    <th>
                        CBTDRAFT PASS CODE:
                    </th>
                    <td>{cbt_passcode}</td>
                </tr>
                <tr>
                    <th>
                        Description:
                    </th>
                    <td>{cbt_data.cbt_name}</td>
                </tr>
                <tr>
                    <th>
                        Total question:
                    </th>
                    <td>{cbt_passcode}</td>
                </tr>
                <tr>
                    <th>
                        Test Id:
                    </th>
                    <td>{test_id}</td>
                </tr>
            </table>

            <table className=" table w-25 mt-3 mb-3">
            <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "70%" }}
            value={cbtbarcode}
            viewBox={`0 0 256 256`}
            />
            </table>
            </div>
            {/* verify cbt pass code start */}

    

    
                {/* verify cbt pass code start */}
                <div className="card card-size mx-auto mt-2" style={{padding:'1%'}}>
                <form className="" onSubmit={cbt_permit_code_manual_verification} >
                    
                    <div className="form-group mt-2">
                            <input value={cbt_test_id_verify} onChange={(e)=>set_cbt_test_id_verify(e.target.value)}  placeholder="Enter CBT PERMIT PASS CODE" />
                    </div>
                    <div className="form-group mt-2">
                            <input value={cbt_pass_code_info} onChange={(e)=>set_cbt_pass_code_info(e.target.value)}  placeholder="Enter CBT information" />
                    </div>
                    <div className="form-group mt-2 mb-5">
                        <button className="btn btn-danger w-100">
                           Take CBTDRAFT
                        </button>
                    </div>   
                </form>
            </div>
            {/* verify cbt pass code end */}

          </main>
    </d-section>    
            </>
     )
    
}

const CbtQuestionMap = (props)=>{
    const submitAnswer = (e)=>{
        e.preventDefault();
    } 
    <form key={props.data.id} className="w-100 mx-auto mt-3" onSubmit={submitAnswer} >
        <input  name="cbt_question_id" type="hidden" value={props.data.cbt_question_id} />
        <div className="card  card-size" style={{position: 'static'}}>
            <div className="card-header">
                        <p className="text-justify">{props.data.cbt_question}</p>
            </div>
            <div className="card-body">
                 {props.data.cbt_answer.map((option_data)=>(

      
        <div className="form-group mt-2 mb-1 d-flex align-items-center">
                        <button type="submit" name="question" className="w-100 btn btn-danger">{option_data.options}</button>
                </div>   
                                ))}
            </div>
        </div>            
                </form>

} 




const CbtQuestion = ()=>{

    // cbt questions  state

    const [db_cbt_question, set_db_cbt_question] = useState([]);
    const [cbt_data, set_cbt_data] = useState([]);

    const url_param = new URLSearchParams(window.location.search);
    const cbt_pass_code = url_param.get("cbt_pass_code");
    const cbt_permit_code = url_param.get("cbt_permit_pass_code");
    const cbt_test_id = url_param.get("cbt_test_id");
    const navigate = new useNavigate();
    const cbtbarcode = "https://cbtdraft.com/cbt_user/cbt_pass_code?cbt_pass_code="+cbt_pass_code;

    const Ref = useRef(null);
    const [timer, setTimer] = useState("00:00:00");
    
    const [cbt_question_id, set_cbt_question_id] = useState("");
    const [cbt_option_id, set_cbt_option_id] = useState("");
   


    if(!cbt_pass_code){
        navigate("/cbt_user/cbt_pass_code/code");

    }    

    const finalizedCbt = ()=>{

        alert("do you want to submit this cbt");
        toast("do you want to submit this cbt");
        const data = {
            cbt_pass_code : cbt_pass_code,
            cbt_test_id : cbt_test_id
        }
        axios.post(StudentGradeSubmit, data, ajaxconfig).then((response)=>{
            toast(response.data.message);
            console.log(response);
        }).catch((error)=>{
            toast(error);
            console.log(error);
        })
    }

    const getTimerRemaining = (e)=>{
        
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total/1000) % 60);
        const minutes = Math.floor((total/1000) % 60);
        const hours = Math.floor((total/ 1000 / 60 /60) % 24);

        return {

            total, 
            hours,
            seconds,
            minutes
        };
    }

    const startTimer = (e) =>{
    
        let { total, hours, seconds, minutes } = getTimerRemaining(e);

        if(total >= 0){

            setTimer((hours > 9 ? hours: "0" + hours) + ":" + (minutes > 9 ? minutes :  "0" + minutes) + ":" + (seconds > 9 ? seconds : "0" + seconds));
        }
    }

    const clearTimer = (e)=>{
        // from db
        setTimer("01:59:10");

        if(Ref.current) clearInterval(Ref.current);

        const id = setInterval(()=>{
            startTimer(e);
        }, 1000);
        Ref.current = id;
    }

    const getDeadline = ()=>{

        // additional time
        let deadline = new Date();
        deadline.setSeconds(deadline.getSeconds() + 100);
         return deadline;
    }

    
    useEffect(()=>{
            const GetQuestion = PublicMassCbtQuestionGet + cbt_pass_code+"/"+cbt_permit_code;
            console.log(GetQuestion);
            clearTimer(getDeadline());
        // fetching question 
        const url = VerifyPublicGetCbtPassCode + cbt_pass_code; 

        axios.get(url, ajaxconfig).then((response)=>{
            toast(response.data.message);
            set_cbt_data(response.data.data);
            console.log(response); });

        
        axios.get(GetQuestion, ajaxconfig).then((response)=>{
            toast(response.data.message);
            console.log(response);

            set_db_cbt_question(response.data.data);
        }).catch((error)=>{
            toast(error);
            console.log(error);
        });

        // timer

     
    },[cbt_pass_code, cbt_permit_code]);
    
   
    

    
    return (

         <>
       
            <d-section className="main-container">
        
        <main>
        <ToastContainer />
            <div className="header-container w-100" >
    
                 <TopMenu />
            </div>

                    {/* verify cbt pass code start */}
                    <div className="custom-card d-flex flex-wrap" style={{marginTop: '7%'}}>
                    <div className="w-25 mt-3 mb-3">
                        <QRCode
    size={256}
    style={{ height: "auto", maxWidth: "100%", width: "70%" }}
    value={cbtbarcode}
    viewBox={`0 0 256 256`}
    />
                        </div>
                        <table className="table w-75 mt-3 mb-3">
                        <tr>
                                <th>
                                    Cbt Test Id:
                                </th>
                                <td>{cbt_test_id}</td>
                            </tr>
                            <tr>
                                <th>
                                    CBTDRAFT PSSC:
                                </th>
                                <td>{cbt_pass_code}</td>
                            </tr>
                            <tr>
                                <th>
                                    Description:
                                </th>
                                <td>{cbt_data.cbt_name}</td>
                            </tr>
                            <tr>
                                <th>
                                    Duration:
                                </th>
                                <td>{cbt_data.cbt_duration}</td>
                            </tr>
                        </table>

                        
                    </div>
                    {/* verify cbt pass code start */}

            {/* verify cbt pass code start */}
            <div className="custom-card mt-3">
                {

db_cbt_question.map((data)=>(
    

                  <Obj cbt_pass_code={cbt_pass_code} cbt_test_id={cbt_test_id} qdata={data} cbt_question_id={data.cbt_question_id} user_option={data.user_option}  /> 

            ))
                }
            </div>
            {/* verify cbt pass code end */}



    {/* verify cbt pass code start */}
<div className="custom-card" style={{position: 'fixed', bottom: '0'}}>
               <div className="card mx-auto w-75">
                    <div className="card-header d-flex justify-contents-space-between">
                    <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#myModal">
  Open modal
</button>
                        <button style={{width: '40%'}} onClick={finalizedCbt} className="btn btn-danger mx-auto">Submit</button>
                    </div>
            </div>
</div>
            {/* verify cbt pass code end */}

   
            
          </main>
          
    </d-section>    

            </>
     )
}


const update_option = (props)=>{

    return(<>



        <form  className="w-100 mx-auto mt-3 mb-5" >
                            
        
                            <div className="card w-75 mx-auto" style={{position: 'static'}}>
                                <div className="card-header">
                                            <p className="text-justify">test</p>
                                           
                                </div>
                                <div className="card-body">
                                     
                    
                          
                            <div className="form-group mt-2 mb-1">
                                            <button  type="submit" name="question" className="w-100 btn btn-danger">
                                         <h6>       option 
                                     
                                        </h6>
                                            </button>
                                            <p className="text-justify mb-3">{props.click_option}</p>
                                           
                                    </div>   
                                                    
                                </div>
                              
                            </div>            
                                    </form>
         
        
            </>);
}

const Obj = (props)=>{


    
    const [cbt_question_id, set_cbt_question_id] = useState("");
    const [cbt_option_id, set_cbt_option_id] = useState("");
    const [click_state, set_click_state] = useState(false);
    const [click_option, set_click_option] = useState("");
    // const [user_option, set_user_option] = useState("");

    // if(props.user_option){
    //     set_user_option(props.user_option.cbt_option_id);
    // }
    

    const submitAnswer = (e)=>{
        e.preventDefault();
      

            set_click_state(true);
            set_click_option(cbt_option_id);
               

        // send the question id and the answer id to db
       
        const data = {
            cbt_question_id: cbt_question_id,
            cbt_option_id: cbt_option_id,
            cbt_pass_code : props.cbt_pass_code,
            cbt_test_id: props.cbt_test_id

        }
        axios.post(StudentAnswerSubmit, data, ajaxconfig).then((response)=>{
            toast(response.data.message);
            console.log(response);
        }).catch((error)=>{

            toast(error);
            console.log(error);
        })

    } 



    return(<>



<form key={props.qdata.id} className="w-100 mx-auto mt-3 mb-5" onSubmit={submitAnswer}>
                    

                    <div className="card w-75 mx-auto" style={{position: 'static'}}>
                        <div className="card-header">
                                    <p className="text-justify">{props.qdata.cbt_question}</p>
                                   
                        </div>
                        <div className="card-body">
                             {props.qdata.cbt_answer.map((option_data)=>(
            
                  
                    <div className="form-group mt-2 mb-1">
                                    <button type="submit"  name="question"  className="w-100 btn btn-danger">
                                 <h6>       option
                                {props.user_option ?
                                option_data.answer_id === props.user_option.cbt_option_id ? <img style={{width: '20px'}} src="/asset/user_option.png" /> : ''
                                
                                 : ''}

<input type="radio" onClick={()=>{set_cbt_option_id(option_data.answer_id); set_cbt_question_id(props.cbt_question_id)}} name="option" />
                                {/* {option_data.answer_id == props.user_option.cbt_option_id ? 'user select' : 'h'} */}
                                </h6>
                                    </button>


                                    <p className="text-justify mb-3">{option_data.options}</p>
                                   
                            </div>   
                                            ))}
                        </div>
                      
                    </div>            
                            </form>
 

    </>);
}

const Essay = (props)=>{

    return (<>
    
    <div className="custom-card mt-2" style={{marginBottom: '10%'}}>
                <form className="w-100 mx-auto" >
                    <div class="form-group mt-2">
                        <p class="text-justify">The equipment used to do a particular job at home is called</p>
                    </div>
                    <div className="form-group mt-2 mb-2 d-flex align-items-center">
                      <textarea placeholder="Start typing..." rows="6" />
                        
                    </div>                    
                       
                    
                </form>
            </div>

    </>)
}



export {CbtPassCode, CbtPassCodeVerification, CbtQuestion}
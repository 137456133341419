// import './home_page.css';
import React, { useState} from "react";
import { Header } from "./cbt_lms/header";
import { Footer } from "./cbt_lms/footer";
import {Catwidget} from "./cbt_lms/categorywidget";


function Category(){

    const [blogtitles, set_blog_titles] = useState([]);

    return (
        <>

<Header />
    <div className="container mt-4">
    




{/* cbt category  start */}

<Catwidget />
{/* cbt category end */}

<section className="card static">
        <h1 className="text-center">Free CBT Questions</h1>
</section>

    {blogtitles.map((data)=>(

<section className="card static first-home-page-section" >
    <div className="cbt_draft">
        <img src="/asset/student.jpg" className="img_homepage" alt="student learning using LMS" />
    </div>
    <div className="cbt_draft">

    
        <h3 className="text-center"><a className="nav-link" href={"/cbt?cbt_pass_code=" + data.cbt_data_id} >{data.cbt_name}</a></h3>
    </div>
</section>

    ))}

{/* cbt category  start */}

<Catwidget />
{/* cbt category end */}


    </div>

<div className="footer card" style={{marginTop: '7%'}}>
    <Footer />
</div>
        </>
    );
}

export default Category;
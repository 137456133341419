import React from 'react';
import {useState} from "react";
import { SignUpUrl } from './serverurl';
import { Header } from './header';
import { Footer } from './footer';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


function Signup(){

    // active button
    const hidden = {
        visibility: 'hidden'
    };

    const visible = {
        visibility: 'visible'
    };


    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone_number, setPhone_number] = useState("");
    const [password, setPassword] = useState("");
    const [button_active, set_button_active] = useState(visible);
    const [button_effect, set_button_effect] = useState(hidden);

    const navigate = useNavigate();
   const new_signup = (event)=>{
        
        event.preventDefault();

        set_button_active(hidden);
        set_button_effect(visible);

        const data = {
        name: name,
        email: email,
        phone_number: phone_number,
        password: password}

        axios.post(SignUpUrl, data)
          .then(function (response) {
            console.log(response);

            alert(response.data.message);
            set_button_active(visible);
            set_button_effect(hidden);

            if(response.data.message == "account created successfully"){
                set_button_active(visible);
                set_button_effect(hidden);
                navigate("/login");
            }


          })
          .catch(function (error) {
      
            alert("pleae try again...");
            set_button_active(visible);
            set_button_effect(hidden);
          });
    }
    return(
        <>

<Header />

        <div className="container default-margin-top" >

            
               
<div className="card mx-auto static card-size" >

      <form onSubmit={new_signup}>
                        <h5 className="text-center"><b><a className="nav-link" href="https://cbtdraft.com">Sign Up Form</a></b></h5>
                        <div className="form-group">
                            <label>Name: <input type="text"  value={name} onChange={(e)=>setName(e.target.value)} name="name" placeholder="Enter Name" /> </label>
                        </div>
                        <div className="form-group">
                            <label>Email <input type="email"  value={email} onChange={(e)=>setEmail(e.target.value)} name="email" placeholder="Enter Email" /> </label>
                        </div>

                        <div className="form-group">
                            <label>Mobile No: <input type="text"  value={phone_number} onChange={(e)=>setPhone_number(e.target.value)} name="phone_no" placeholder="+12342123456789" /> </label>
                            <p className="mt-1">include country code, e.g. +123</p>
                        </div>
                        <div className="form-group mt-3">
                            <label>Password: <input type="password" value={password} onChange={(e)=>setPassword(e.target.value)} name="password" placeholder="Enter Password"  /> </label>
                        </div>
                        <div className="form-group mt-3">
                            <p className="text-justify">
                                You have agree to the terms and condition by using <a style={{color: 'red'}} href="/tos">cbtdraft.com</a>
                            </p>
                        </div>
                        <div className="form-group mt-1" style={button_active}>

                            <button className="btn btn-danger w-100 mb-1" >Sign Up</button>
                        </div>

                        <div className="form-group mt-1" style={button_effect}>

                                <button className="btn btn-danger w-100 mb-1"  disabled><span className="spinner-border text-primary" /></button>
                        </div>
                    </form>
   <div className="form-group mx-auto" style={{width:'30%'}}>

   </div>
  <div className="form-group mt-2">
    <h6 style={{marginLeft: '40px', display:'flex', flexDirection: 'row', alignItems:'center'}}><a className="nav-link" href="/password-reset"><b>Reset Password</ b></ a> <a className="nav-link" href="/login"><b>Already have account</b></a> </ h6>
  </div>
</div>


<div className="footer card">
<Footer />
</div>
</div>

        </>
    );
 }

export  default Signup;
import React from "react";
import { useNavigate  } from 'react-router-dom';


function TopMenu(){

    const navigate = useNavigate();
    const home =()=> {
        navigate("/cbt_creator/dashboard");
}
    const OpenCloseSideBar = ()=>{
        // get the element by id 

        const menu = document.getElementById("main");
        const sideMenu = document.getElementById("side-menu");
        const SideMenuContent = document.getElementById("side-menu-content");


        // side menu
        if(sideMenu.className === "side-close"){
            
            // side menu content
            if(SideMenuContent.className === "side-menu-content-close"){
                SideMenuContent.classList.remove("side-menu-content-close");
                SideMenuContent.classList.add("side-menu-content-open");
            }else{

                SideMenuContent.classList.remove("side-menu-content-open");
                SideMenuContent.classList.add("side-menu-content-close");
            }
            // side menu
            sideMenu.classList.remove("side-close");
            sideMenu.classList.add("side-open");
        }else{
            // side menu content
            if(SideMenuContent.className === "side-menu-content-close"){
                SideMenuContent.classList.remove("side-menu-content-close");
                SideMenuContent.classList.add("side-menu-content-open");
            }else{

                SideMenuContent.classList.remove("side-menu-content-open");
                SideMenuContent.classList.add("side-menu-content-close");
            }


            // side menu
            sideMenu.classList.remove("side-open");
            sideMenu.classList.add("side-close");
        }


        // main dashboard
        if(menu.className === "main-close"){
            // display the menu
            menu.classList.remove("main-close");
            menu.classList.add("main-open");
            
        }else{

            // hide the menu
            menu.classList.remove("main-open");
            menu.classList.add("main-close");
        }
    }
    return(
    <>
           <div className="container header" >
            <a onClick={OpenCloseSideBar} href="javascript:void(0)" ><img src="/asset/dashboard.png"/></a>
        <div>
        <a onClick={home} href="javascript:void(0)" ><img src="/asset/home.png"/></a>        </div>    

            </div>
    </>
    )
}


export default TopMenu;
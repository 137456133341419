import { Header } from "./header"
import { Footer } from "./footer"


const Notfound = ()=>{

    return(<>
    <Header />
    <div className="container default-margin-top">
{/*  */}
    <div className="card mt-5 mx-auto static">

           
            <div className="card-body">
            <p className="text-center"><img style={{width: '45%'}} src="/asset/avatar.jpg" /></p>
               <p className="text-center mx-auto">404-page not found</p>
               <p className="text-justify">create account / login to existing account <a style={{color: 'red'}} href="/login">Here</a></p>
               <p className="text-justify">participate in free cbt quiz <a style={{color: 'red'}} href="/">Here</a></p>
            </div>

    </div>
{/*  */}
    </div>
    <div className="footer card">
<Footer />
</div>
    </>)
}


export {
    Notfound
}
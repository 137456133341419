import { useState } from "react"
const Header = ()=>{

    const [menu_state, set_menu_state] = useState("header-close"); 
    const menu = ()=>{

        if(menu_state === "header-close"){
            set_menu_state("header-open");
        }else{
            set_menu_state("header-close");
        }
    }
   return(<>
    <nav className="mobile card nav-holder" >
            <div className="container nav-box mt-1 mb-1">
                <navbar>
                <a className="nav-link" href="/"><img className="site-logo"  src="/asset/singlelogo.png" alt="cbtdraft logo" /></a>
                </navbar>
                <navbar className="d-flex justify-content-space-around">
                         <button  class="btn btn-light" ><img onClick={menu} src="/asset/dashboard.png" /></button>
                </navbar>
            </div>
            <div className={menu_state} >
                <ul>
                    <li><a className="nav-link" href="/login" ><b>Login</b></a></li>
                    <li><a className="nav-link" href="/sign-up"><b>Sign up</b></a></li>
                    <li><a className="nav-link" href="/" ><b>Blog</b></a></li>
                </ul>
            </div>
        </nav>

    <nav className="card non-mobile nav-holder" >
           <div className="container nav-box mt-1">
               <navbar>
                   <a className="nav-link" href="/"><img className="site-logo"  src="/asset/singlelogo.png" alt="cbtdraft logo" /></a>
               </navbar>
               <navbar className="d-flex justify-content-space-around">
                                       <a className="nav-link" href="/login" ><b>Login</b></a>
                   <a className="nav-link" href="/sign-up"><b>Sign up</b></a>
                   <a className="nav-link" href="/"  ><b>Blog</b></a>

               </navbar>
           </div>
       </nav>

   </>)
}


export {Header}
import React from 'react';
import {useState} from 'react';
import {Footer} from "./footer";
import { Header } from './header';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { LoginUrl, gmailLogin } from './serverurl';
import { useNavigate  } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet-async';





function Login(){

        // active button
        const hidden = {
            display: 'none'
        };
    
        const visible = {
            visibility: 'block'
        };
    

    const [button_active, set_button_active] = useState(visible);
    const [button_effect, set_button_effect] = useState(hidden);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [processing, set_processing] = useState(hidden);
    const [disabled_status, set_disabled_status] = useState("none");

   
    // headers end
    const responseMessage = (response) => {
        const credentialData =jwtDecode(response.credential);
        set_processing(visible);
        console.log(response);
        const data = {
            email: credentialData.email,
            name: credentialData.name
        }

        axios.post(gmailLogin, data).then((response)=>{
            toast(response.data.message);



            console.log(response);
            if(response.data.serverstatus !== 401){

                console.log(response.data);

                const user_id_cookies = new Cookies(null, {path:'/'});
                const auth_key_cookies= new Cookies(null, {path:'/'});
                const api_key_cookies = new Cookies(null, {path:'/'});
                const auth_refresh_key = new Cookies(null, {path:'/'});
                const surname_cookies =  new Cookies(null, {path:'/'});
                const name_cookies = new Cookies(null, {path:'/'});
                const email_cookies = new Cookies(null, {path:'/'});
                const autheticated = new Cookies(null, {path:'/'});

                user_id_cookies.set('user_id', response.data.user_id);
                auth_key_cookies.set('auth_key', response.data.user_id);
                api_key_cookies.set('api_key', response.data.user_id);
                auth_refresh_key.set('auth_refresh_key', response.data.user_id);
                surname_cookies.set('surname', response.data.surname);
                name_cookies.set('name', response.data.name);
                email_cookies.set('email', response.data.email);
                autheticated.set('autheticated', true);
         
                navigate("/cbt_creator/dashboard"); }else{
                    set_processing(hidden);
                    toast(response.data.message);
                }
        }).catch((error)=>{
            toast(error);
            console.log(error);
        })
    };
    const errorMessage = (error) => {
        toast(error);
        console.log(error);
    };
    const navigate = useNavigate();
    
           
            let login_form = (e)=>{
                e.preventDefault();

                // button effects
                set_button_active(hidden);
                set_button_effect(visible);

                set_disabled_status("disabled");
                const data = {
                    email : email,
                    password: password
                }

             
        //  ajax request
        axios.post(LoginUrl, data).then((response)=>{
            // console.log(response);

            set_button_active(visible);
            set_button_effect(hidden);

            if(response.status !== 200){
                toast("Please try again....");
                // alert("Please try again....");
                set_disabled_status("none");

            }
            if(response.status === 200){

                if(response.data.serverstatus !== 401){

                    console.log(response.data);

                    const user_id_cookies = new Cookies(null, {path:'/'});
                    const auth_key_cookies= new Cookies(null, {path:'/'});
                    const api_key_cookies = new Cookies(null, {path:'/'});
                    const auth_refresh_key = new Cookies(null, {path:'/'});
                    const surname_cookies =  new Cookies(null, {path:'/'});
                    const name_cookies = new Cookies(null, {path:'/'});
                    const email_cookies = new Cookies(null, {path:'/'});
                    const autheticated = new Cookies(null, {path:'/'});

                    user_id_cookies.set('user_id', response.data.user_id);
                    auth_key_cookies.set('auth_key', response.data.user_id);
                    api_key_cookies.set('api_key', response.data.user_id);
                    auth_refresh_key.set('auth_refresh_key', response.data.user_id);
                    surname_cookies.set('surname', response.data.surname);
                    name_cookies.set('name', response.data.name);
                    email_cookies.set('email', response.data.email);
                    autheticated.set('autheticated', true);
                    set_button_active(visible);
                    set_button_effect(hidden);
                    toast(response.data.message);
                    navigate("/cbt_creator/dashboard");
                }else{
                    // restrict user from login
                    toast(response.data.message);
                    set_disabled_status("none");


                    set_button_active(visible);
                    set_button_effect(hidden);
        
                }
                
            }
            
            
        }).catch((error)=>{ 
            toast(error.message);
            console.log(error);
            set_disabled_status("none");

            set_button_active(visible);
            set_button_effect(hidden);


         });
        
        }    
    


   
    return(
        <>
        <Helmet>
        <meta id="description" name="discription" content="cbtdraft login" />
        <title id="title">cbtdraft.com | login</title>
      </Helmet>


        <Header />
               
                <div className="container default-margin-top" >
                    {/*  */}
                    <div className="card mx-auto static card-size">
                 <h3 className="text-center"><a className="nav-link" href="https://cbtdraft.com" ><b>CBTDRAFT.COM</b></a></h3>
                 <div className="form-group mt-1 mx-auto">
            
            <GoogleLogin className="static" onSuccess={responseMessage} onError={errorMessage} />
        </div>
        <h6 className="text-center mt-2" style={processing} ><span  className="spinner-border spinner-border-sm text-success" />please wait, while the server is processing your request</h6>
                 <form onSubmit={login_form} style={{ width: '85%', borderRadius: '5%'}} className="mx-auto" >
                       
                       <div className="form-group">
                           <input type="email" value={email} onChange={(e)=>setEmail(e.target.value)} placeholder='email' name="email" />
                       </div>
                       <div className="form-group mt-1">
                           <input type="password" value={password} onChange={(e)=>setPassword(e.target.value)} placeholder="Password" name="password" />
                       </div>
                       <div className="form-group mt-1 mb-1" style={button_active}>
                           <button className="btn btn-danger w-100">Login</button>
                       </div>


                       <div className="form-group mt-1 mb-1" style={button_effect} >
                           <button className="btn btn-danger w-100" disabled><span className="spinner-border spinner-border-sm text-primary" /></button>
                       </div>


                   </form>
                   <ToastContainer />
                   <div className="form-group mt-1">
                     <h6 style={{marginLeft: '40px', display:'flex', flexWrap: 'wrap', flexDirection: 'row', alignItems:'center'}}><a className="nav-link" href="/password-reset"><b>Reset Password</ b></ a> <a className="nav-link" href="/sign-up"><b>Sign Up</b></a></ h6>
              
                   </div>
                </div>
                    {/*  */}
                </div>
                
            
      
            
<div className="footer card" >
    <Footer />
</div>

        </>

    );
}




export  default Login;


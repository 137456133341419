import React from "react";
import { useNavigate  } from 'react-router-dom';


function TopMenu(){

    const navigate = useNavigate();

    const home =()=> {
        navigate("/cbt_creator/dashboard");
}
    return(
    <>
           <div className="container header w-100">
            <a href="javascript:void(0)" ><img src="/asset/dashboard.png"/></a>
        <div>
        <a onClick={home} href="javascript:void(0)" ><img src="/asset/home.png"/></a>
        </div>    

            </div>
    </>
    )
}


export default TopMenu;
import React from "react";

const Footer = ()=>{
    return(
        <footer className="container mx-auto">


        <div className="footer-box mt-2">
            <div className="footer-boxes mx-auto">
                <ul style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                    <a className="nav-link" href="/tos"><b>TOS</b></a>
                    <a className="nav-link" href="/privacy"><b>Privacy</b></a>
                    <a className="nav-link" href="/contact-us"><b>Contact</b></a>
                    <a className="nav-link" href="/about-us"><b>About</b></a>  
                </ul>
            </div>
        </div>

        <div id="foremediaads-footer_float"></div>
<script type="text/javascript" async src="https://platform.foremedia.net/code/53517/footer_float"></script>
    </footer>
    )
}


export {Footer}
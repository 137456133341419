import cookie from "universal-cookie";
const cookies = new cookie();
const ajax_config_file ={
    // getting user data from cookies 
    headers:{
        'auth_key' : cookies.get("auth_key"),
'auth_refresh_key' : cookies.get("auth_refresh_key"),
'api_key' : cookies.get("api_key"),
'email' : cookies.get("email"),
'user_id' : cookies.get("user_id"),
'Access-Control-Allow-Origin': '*',
'Content-Type' : 'multipart/form-data',
    }
}

export {ajax_config_file}
import React from "react";
import {useParams } from 'react-router-dom';
import {Header} from "./header";
import { Footer } from "./footer";
import { Helmet } from 'react-helmet-async';
import axios from "axios";
import { useEffect, useState } from "react";
import { ajaxconfig } from "./ajaxconfig";
import { SinglePost, ServerImage } from "./serverurl";



function Blog_view(){

        const [post, set_post] = useState("");
        const [post_title, set_post_title] = useState("");
        const [post_youtube, set_post_youtube] = useState("");
        const [post_description, set_post_description] = useState("");
        const [post_image, set_post_image] = useState("");
        const [all_post, set_all_post] = useState([]);
        // get the url parameter
        const url_param = new URLSearchParams(window.location.search);
        const {post_id} = useParams(); 
        const url = SinglePost+post_id;
        console.log(url);
    useEffect(()=>{
        axios.get(url, ajaxconfig).then((response)=>{
            console.log(response);
            set_all_post(response.data.all_post);
            console.log(response.data.all_post);
            set_post(response.data.post.post);
            set_post_title(response.data.post.title);
            set_post_youtube(response.data.post.youtube);
            set_post_description(response.data.post.description);
            set_post_image('images/'+response.data.post.image);

        }).catch((error)=>{
            console.log(error);
        });
    }, []);
    return (
        <>
    <Helmet>
        <meta id="description" name="discription" content={post_description} />
        <title id="title">{post_title}</title>
      </Helmet>
<Header />
    <div className="container mt-4">
    


<section className=" mt-4">
    
    <section className="card static mt-5" >
        <div className="post_image">
            <img src={ServerImage+post_image} style={{width:'60%'}} className="img_homepage" alt="create online test/quiz" />

        </div>
        <div className="post_title">
            <h1>{post_title}</h1>
        </div>

        <div dangerouslySetInnerHTML={{__html:post}} className="post_body" />
        <div dangerouslySetInnerHTML={{__html:post_youtube}} className="post_body" />
            
    </section>

</section>
<h1 className="text-center mt-2"><b>Read more articles for help</b></h1>
{
            
    all_post.map((all_post)=>(
        <section className="mt-1 static">

        
    <section className="card static first-home-page-section" >
        <div className="cbt_draft">
            <img src={ServerImage+'images/'+all_post.image} className="img_homepage" alt="student learning using LMS" />

        </div>
        <div className="cbt_draft">
           <div dangerouslySetInnerHTML={{__html:all_post.description}} />

            <div className="flex-center">
                    <a className="ml-2" href={'/blog/post/'+all_post.anchor}><button className="btn btn-info">{all_post.title}</button></a>
            </div>
       
        </div>
    </section>

</section>
    ))
}

    </div>

<div className="footer card" style={{marginTop: '7%'}}>
    <Footer />
</div>
        </>
    );
}

export default Blog_view;
import { Header } from "./header";
import { Footer } from "./footer";
import { Helmet } from 'react-helmet-async';




const AboutUs = ()=>{

    return(<>

<Helmet>
        <meta id="description" name="discription" content="cbtdraft about us" />
        <title id="title">cbtdraft.com | about us</title>
      </Helmet>

    <Header />
   <div className="container default-margin-top">
       <div className="card mt-5 static">

            <div className="card-body">
            <h3 className="text-center">About US</h3>

            <p className="text-justify">
                CBTDRAFT.COM is free automated computer based testing software which helps instructors to evaluate learners, cbtdraft also has cbt and lms-learning management features. 
            </p>
            
            </div>
        </div>


        <div className="card mt-3 static">
            <div className="card-body">
                <h3 className=""><img title="C.E.O cbtdraft" style={{width: '100px'}} alt="C.E.O cbtdraft" src="/images/creator.jpg" /></h3>
                <p className="text-justify mt-3">
                I am Mr. Moyeso (official name: Zaccheaus moyeso), a undergraduate computer science student at Nasarawa State 
                University, Nasarawa state, Nigeria. I created cbtdraft.com project, when i was in 300L in the year 2024, cbtdraft is a software which help instructors to evaluate learners which has multi choice quiz /  question and answer, 
                objectives features, please you can also contact me to add suggestion/ support fund to the project, through the following channels below
            </p>
            <p className="mt-2">
                Mr. Moyeso
            </p>
            <h5 className="mt-1">Email: moyeso@cbtdraft.com, support@cbtdraft.com</h5>
            <h5 className="mt-1">Whatsapp/Tel: +2348113315252, +2349039421996</h5>
            <h5 class="mt-1">C.E.O cbtdraft.com</h5>
            </div>
        </div>

   </div>
    <div className="footer card">
<Footer />
</div>
    </>)
}


export {
    AboutUs
}
import React from "react";
import {Header} from "./header";
import { Footer } from "./footer";
import { PostTitles, ServerImage } from "./serverurl";
import axios from "axios";
import { useEffect, useState } from "react";
import { Helmet } from 'react-helmet-async';
const Description = (props)=>{

console.log(props.content.title);
    return(
<>

        <section className="card static mt-3">
<h3>{props.content.title}</h3>
{props.content.description}

</section>

        </>
        );

}

function Homepage(){

    const [post, set_post] = useState([]);
    const [home_content, set_home_content] = useState([]);
    useEffect(()=>{

        axios.get(PostTitles).then((response)=>{
            set_post(response.data.data);
            set_home_content(response.data.home_page);
        }).catch((error)=>{
            console.log(error);
        });
    }, [])
    
    return (
        <>
<Helmet>
        <meta id="description" name="discription" content="create cbt quiz online free, using cbtdraft free knowledge evaluation tools to evaluate learners" />
        <title id="title">cbtdraft.com | create cbt quiz online free</title>
      </Helmet>
<Header />
    <div className="container mt-4">
    

<section className="card static first-home-page-section" >
        <div className="cbt_draft">
            <img src="/asset/singlelogo.png" className="img_homepage" alt="cbtdraft logo" />

        </div>
        <div className="cbt_draft">
            <p className="text-justify">
            cbtdraft is an online free evaluation assessment tool which allows you to create your own cbt tests, quizzes and exams.
            </p>       
        </div>
    </section>
    <div id="foremediaads-e1"></div>
<script type="text/javascript" async src="https://platform.foremedia.net/code/53517/e1"></script>
    <section className="card static first-home-page-section" >
        <div className="cbt_draft">
            <img src="/asset/student_cbt.jpg" className="img_homepage" alt="student learning using LMS" />

        </div>
        <div className="cbt_draft">
            <p className="text-justify">
                create online cbt quiz test using our robust cbt features: reusable diagrams, built calculator, smart converter, automatic grading system, export and import csv data, team , built calculator etc.
            </p>
            <div className="flex-center">
                     <a className="mr-2" href="https://cbtdraft.com/blog/" target="_blank"><button className="btn btn-danger">Blog</button></a>
                    <a className="mr-2" href="/login"><button className="btn btn-danger">Login</button></a>
                    <a className="ml-2" href="/sign-up"><button className="btn btn-danger">Sign Up</button></a>
            </div>
       
        </div>
    </section>
<section className=" mt-2">
<h1 className="text-center">Faqs</h1>
</section>
{
    home_content.map((content)=>(
        <section className="card static mt-1">
        <h1>{content.title}</h1>
        
        <div dangerouslySetInnerHTML={{__html:content.title}} />
        <div dangerouslySetInnerHTML={{__html:content.description}} />  
        </section>

    ))
}
    

    

<div className="card static mt-2">
        <h3 className="text-center italic"><b>Blog</b></h3>
</div>
{
    post.map((data)=>(
        <section className=" mt-1">
    
        <section className="card static first-home-page-section" >
            <div className="cbt_draft">
                <img src={ServerImage+'images/'+data.image} className="img_homepage" alt="student learning using LMS" />
    
            </div>
            <div className="cbt_draft">
                <div dangerouslySetInnerHTML={{__html:data.description}} />
                <div className="flex-center">
                        <a className="nav-link" href={'/blog/post/'+data.anchor}>{data.title}</a>
                </div>
           
            </div>
        </section>
    
    </section>
    ))
}


    </div>

<div className="footer card" style={{marginTop: '7%'}}>
    <Footer />
</div>
        </>
    );
}

export default Homepage;
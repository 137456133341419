import React from "react"
const Catwidget = ()=>{

    return (<>
    
    <section className="card default-margin-top static">
    <div className="card-header">
        <h2 className="text-center">CBT Category</h2>
    </div>
    <div className="d-flex flex-wrap">
        <button className="btn btn-danger mt-2 ml-2">Waec</button>
        <button className="btn btn-danger mt-2 ml-2">Computer interview</button>
        <button className="btn btn-danger mt-2 ml-2">Java interview</button>
        <button className="btn btn-danger mt-2 ml-2">Java interview</button>
        <button className="btn btn-danger mt-2 ml-2">Java interview</button>
        <button className="btn btn-danger mt-2 ml-2">Java interview</button>
        <button className="btn btn-danger mt-2 ml-2">Java interview</button>
        <button className="btn btn-danger mt-2 ml-2">Java interview</button>
        <button className="btn btn-danger mt-2 ml-2">Java interview</button>

    </div>
</section>
    
    </>)
}

export {Catwidget}

import React, { Fragment } from 'react';
import {useState,  useEffect} from 'react';
import axios from 'axios';
import TopMenu from "./components/top_menu";
import SideMenu from "./components/side_menu";
import { ajaxconfig } from "../ajaxconfig";
import { ajax_config_file } from '../ajax_config_file';
import {upload_file, ImgFile, private_all_asset} from "../serverurl";







const CbtImgLibrary = ()=>{

    

    const [file, set_file] = useState("");
    const [db_img_files, set_db_img_file] = useState([]);


    const handlefile = (e)=>{
            set_file(e.target.files[0]);
            console.log(e.target.files[0]);
            const formData = new FormData();
         
                formData.append('uploaded_file', file) 
            axios.post(upload_file, formData, ajax_config_file).then((response)=>{

                console.log(response);
                set_db_img_file(response.data.img_files);
            }).catch((error)=>{
                console.log(error);
            });
    }

    useEffect(()=>{

        // fetch all files 
        axios.get(private_all_asset, ajaxconfig).then((response)=>{
            console.log(response);
            set_db_img_file(response.data.img_files);
        }).catch((error)=>{

            console.log(error);
        });
    }, []);
    return(
      <>
        
        <d-section className="main-container">
    <side  className="side-close" id="side-menu">
       
       <SideMenu />

    </side>
         
        <main  className="main-close" id="main">
        <div className="header-container" style={{width: '100%'}} >
             <TopMenu />
        </div>
           

        
<div className="custom-card" style={{marginTop: '7%'}}>

{/* start */}
<div className="card w-75 mx-auto" style={{position: 'static'}}>
        <div className='card-header'>
            <h5 className='text-center'>Images Library <input type="file" onChange={handlefile} /></h5>
        </div>
        <div className="card-body">
            <div className="d-flex">
                {
                    db_img_files.map((data)=>(
                       <div key={data.id} className="card" style={{width:'25%'}}>
                         <img className="mx-auto" style={{width:'100%', marginLeft: '3%'}} src={ImgFile+data.file_name} alt={data.file_name} />
                        
                        </div>
                    ))
                }
            </div>
        </div>
{/* end card */}
</div>


{/* end custom card */}
</div>
      </main>
</d-section>    
        </>
    )


}

export {CbtImgLibrary};

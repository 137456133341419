
import React, { Fragment } from 'react';
import {useState,  useEffect} from 'react';
import axios from 'axios';
import TopMenu from "./components/top_menu";
import SideMenu from "./components/side_menu";
import { ajaxconfig } from "../ajaxconfig";
import {CreateCbtSession, GetPrivateCbtSession} from "../serverurl";







function CbtSession(){

    const hidden = {
        visibility: 'hidden'
    };

    const visible = {
        visibility: 'visible'
    };

    const [cbt_session_name, set_cbt_session_name] = useState("");
    const [cbt_session_data, list_cbt_session_data] = useState([]);
    // active button
   const [button_active, set_button_active] = useState(visible);
   const [button_effect, set_button_effect] = useState(hidden);


 
    
   useEffect(()=>{
    
    axios.get(GetPrivateCbtSession, ajaxconfig).then((response)=>{
        list_cbt_session_data(response.data.data);
        console.log(response.data.data);
      
    })


   }, [])
      
   
    const submit = (event)=>{
    
        const data = {
            cbt_session_name: cbt_session_name
        }
       
        event.preventDefault();
        set_button_active(hidden);
        set_button_effect(visible);
        // ajaxconfig
        axios.post(CreateCbtSession, data, ajaxconfig).then((response)=>{

            console.log(response);
        console.log(response.data.data);
        list_cbt_session_data(response.data.data);
        set_cbt_session_name("");
            alert(response.data.message);
            set_button_active(visible);
            set_button_effect(hidden);
        }).catch((error)=>{
            alert(error);
            set_button_active(visible);
            set_button_effect(hidden);
            console.log(error);
        });

    }

const modal={
    position: 'fixed',
    top: '10',
    right: '10',
    backgroundColor: 'red'
}

    return(
      <>
        
        <d-section className="main-container">
    <side className="side-close" id="side-menu">
       
       <SideMenu />

    </side>
         
        <main className="main-close" id="main">
        <div className="header-container" style={{width: '100%'}} >
             <TopMenu />
        </div>


<div className="mx-auto w-100" style={{marginLeft: '10%'}}>
        <div className='card w-75' style={modal}>
            <div className='card-header'>
                <b>modal testing</b>
            </div>
        </div>
</div>

<div className="custom-card" style={{marginTop: '8%'}} >
<div className="card static w-100">
    <div className="card-header">
        <h4 className="text-center">
            <b>CBT Session</b>
        </h4>
    </div>
   <div className="card-body">
    <form className="w-75 mx-auto" onSubmit={submit} >

        <div className="form-group-2 mt-2">

            <label><input 
            value={cbt_session_name} onChange={(e)=>set_cbt_session_name(e.target.value)} placeholder="Enter CBT Session..." 
            /> </label>
        </div>
        <div className="form-group-2 mt-1 mb-2"  style={button_active}>
            <button className="btn btn-danger w-100" >Create CBT Session </button>
        </div>
        <div className="form-group mt-1 mb-3" style={button_effect} >
            <button className="btn btn-danger w-100" disabled><span className="spinner-border text-primary" /></button>
        </div>

    </form>

   </div>
</div>
</div>




<div className="custom-card mt-2">
<div className="card static w-100">
        <table className="table mt-2 mb-3">
                <thead>
                    <tr>

                        <th>CBT Session</th>
                        <th>Date</th>
                        <th>Action</th>
                    </tr>
                </thead>  
                <tbody>
                    {

            cbt_session_data.map((cbt_session)=>(
            <tr key={cbt_session.id}>
                <td>{cbt_session.cbt_session_name}</td>
                <td>{cbt_session.created_at}</td>
                <td><button className="btn btn-info">Info</button><button className="btn btn-danger">Delete</button></td>
            </tr>   

            ))
                    }
        
                </tbody>      
            </table>  

</div>
</div>
      </main>
</d-section>    
        </>
    )


}

export default CbtSession;

import React from "react";
function SideMenu(){

    return(
    <>
         <ul className="side-menu-content-close" id="side-menu-content" style={{marginTop: '7%', padding: '5px'}}>
         <li className="mt-1 close"><a href="/cbt_creator/dashboard" className="nav-fle"><b><h6><img src="/asset/close.png"/></h6></b></a></li>
            <li className="mt-1"><a href="/cbt_creator/dashboard" className="nav-fle"><b><h6><img src="/asset/home.png"/> Dashboard</h6></b></a></li>
            <li className=""><a href="/cbt_creator/create_cbt" className="custom-nav-link" ><b><h6><img src="/asset/education.png" /> CBT</h6></b></a></li>
            <li className=""><a href="/cbt_creator/cbt_img_library" className="custom-nav-link" ><b><h6><img src="/asset/education.png" /> Library</h6></b></a></li>
            <li className=""><a href="/cbt_creator/profile" className="custom-nav-link" ><b><h6><img src="/asset/profile.png" /> Profile</h6></b></a></li>
            <li className=""><a href="/" className="custom-nav-link" ><b><h6><img src="/asset/logout.png"/>Logout</h6></b></a></li>
        </ul>
    </>)
}

export default SideMenu;
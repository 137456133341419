import {useState,  useEffect,forwardRef, useRef, useImperativeHandle} from 'react';
import axios from 'axios';
import TopMenu from "./components/top_menu";
import SideMenu from "./components/side_menu";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ajaxconfig } from "../ajaxconfig";
// import {hidden, visible, button_loader} from "../load_effects";
import { button_loader, hidden, visible } from '../../load_effect';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import {CbtDataIdGetCbt, UpdateCbtDataId, CbtDataIdCreateCbt, CbtDataIdPrivateCbtDataVerify, CbtQuestionCreate, Deletecbtdata,
    MassPrivateQuestionGet, GetMassPrivateCourses, SinglePrivateQuestionGet, CreateCbtQuestionAnswer, SinglePrivateAnswerGet} from "../serverurl";



const Cbtdata_list = forwardRef(({refreshpage, datalist})=>{

        const [show, setShow] = useState(false);
        const [delete_cbt_data, set_delete_cbt_data] = useState("");
        const [edit_cbt_data, set_edit_cbt_data] = useState("");
        const [delete_cbt_data_info, set_delete_cbt_data_info] = useState("");
        const [edit_data_modal, set_edit_data_modal] = useState(false);

        // edit 
        const [cbt_name_f, set_cbt_name ] = useState("");
        const [attempt_limit, set_attempt_limit] = useState(1);
        const [default_score, set_default_score] = useState(1);
        const [cbt_duration, set_cbt_duration] = useState("");
        const [max_question, set_max_question] = useState("");
        const [random_question, set_random_question] = useState("no");
        const [cbtdata, setcbtdata] = useState([]);
        const [duration_style, set_duration_style] = useState(hidden);
        const [max_attempt_style, set_max_attempt_style] = useState(hidden);
        const [score_style, set_score_style] = useState(hidden);
        const [cbt_status, set_cbt_status] = useState('draft');
    
        // edit end

    
      const handleClose = () => {setShow(false); set_edit_data_modal(false); }
    
        const delete_data = ()=>{
            set_delete_cbt_data(datalist.cbt_data_id);
             set_delete_cbt_data_info(datalist.cbt_name);
            setShow(true)
    
        }
    
        const save_cbt_data = (e)=>{

            e.preventDefault();
            const data = {
                'cbt_name' : cbt_name_f,
                'attempt_limit' : attempt_limit,
                'default_score' : default_score,
                'cbt_duration'  : cbt_duration,
                'max_question'  : max_question,
                'random_question_mode' : random_question,   
                'cbt_status' : cbt_status,
                'cbt_pass_code' : datalist.cbt_data_id,
            }
            // console.log("hello world");
                        const updateurl = UpdateCbtDataId;
            axios.post(updateurl, data, ajaxconfig).then((response)=>{
                console.log(response);
                refreshpage();
                set_edit_data_modal(false)
                toast(response.data.message);

            }).catch((error)=>{
    
                toast(error);
                set_edit_data_modal(false)
                console.log(error);
            });

        }
        const editdata = ()=>{
            // alert("12345");
                            set_edit_data_modal(true);
                            set_edit_cbt_data(datalist.cbt_data_id);
                            set_cbt_name(datalist.cbt_name);
                            set_duration_style(visible);
                            set_max_attempt_style(visible);
                            set_score_style(visible);
                            set_cbt_status(datalist.cbt_status);

                            // if(datalist.cbt_status === "publish"){

                            //     document.getElementById("cbt_status_published").checked = true;
                            // }else{
                            //     document.getElementById("cbt_status_draft").checked = true;
                            // }


        }
    
        const delete_cbt = ()=>{
            const data = {
                cbt_pssc : delete_cbt_data
            }
            axios.post(Deletecbtdata, data, ajaxconfig).then((response)=>{
    
                console.log(response);
                setShow(false);
                refreshpage();
            }).catch((error)=>{
                toast(error);
                setShow(false);
                console.log(error);
            });
        }
        return(<>
        
        <tr key={datalist.id}>   
                        <td>{datalist.cbt_name}</td>
                        <td>{datalist.created_at}</td>
                        <td><a href={"/cbt_creator/cbt_data?cbt_data_id="+datalist.cbt_data_id} ><button className="btn btn-info">Info</button></a><button onClick={editdata} className="btn btn-success">Edit</button><button onClick={delete_data} className="btn btn-danger">Delete</button></td>
                    </tr>  

                    <Modal
            show={edit_data_modal}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            scrollable = {true}
          >
            <Modal.Header  closeButton>
              <Modal.Title>Edit cbt data</Modal.Title>
            </Modal.Header>
            <Modal.Body >

            <form className="card static" onSubmit={save_cbt_data}  style={{display: 'flex', flexWrap: 'wrap'}}>




<div className="card w-75 static mt-2 mb-3 mx-auto">
        <div className="card-header">
            <h6 className="text-center">Config</h6>
        </div>
        
        <div className="card-body">
            




            <h4><b>cbt status: </b>{cbt_status}</h4>
            <div className="form-group-2 mt-2" >
                <b>CBT status:</b>
        
               <div style={{display: 'flex'}}>
                <label style={{display: 'flex'}}>
                        <b> Published</b>
                        <input onClick={()=>set_cbt_status('published')} type="radio" className="radio" id="cbt_status_published" name="cbt_status" /> 
                    </label>
                    <label style={{display: 'flex'}}>
                        <b>Draft</b>
                        <input onClick={()=>set_cbt_status('draft')} type="radio" id="cbt_status_draft" className="radio" name="cbt_status" /> 
                    </label>
               </div>

            </div>
            
        {/* laste */}
        </div>
</div> 


<div className="card static w-75 mx-auto" style={{padding: '10px'}}>

                <div className="form-group-2 mt-2">

                    <label>
                    <h6>CBT Name</h6>
                        <input 
                    value={cbt_name_f} onChange={(e)=>set_cbt_name(e.target.value)} placeholder="CBT name" 
                    /> </label>
                </div>

                

            <div className="form-group-2 mt-2" style={max_attempt_style} >

                <label>
                    <h6>Max Attempt</h6>
                    <input input type="number"
                value={attempt_limit} onChange={(e)=>set_attempt_limit(e.target.value)} placeholder="Attempt limit" 
                /> </label>
            </div>

            <div className="form-group-2 mt-2" style={score_style}>

                <label>
                    <h6>Default score</h6>
                    <input input type="number"
                value={default_score} onChange={(e)=>set_default_score(e.target.value)} placeholder="Default score" 
                /> </label>
            </div>
            <div className="form-group-2 mt-2" style={duration_style} >
                <label>
                <h6>Duration in minutes:</h6>
                    <input type="number"
                value={cbt_duration} onChange={(e)=>set_cbt_duration(e.target.value)} placeholder="CBT duration" 
                /> </label>
            </div>

                <div className="form-group-2 mt-1 mb-2">
                    <button className="btn btn-danger w-100" >Save </button>
                </div>
                <button_loader button_effect={visible} />
        
</div>

</form>   

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
    
    
                    <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >

            <Modal.Header closeButton>
              <Modal.Title>Delete cbt</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>on click confirm, you are to delete all {delete_cbt_data}: {delete_cbt_data_info}, and all it data included forever, are you sure?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="primary" onClick={delete_cbt}>Confirm</Button>
            </Modal.Footer>
          </Modal>
    
        </>)
    
});

const Cbt = ()=>{

    

    const [cbt_name_f, set_cbt_name ] = useState("");
    const [attempt_limit, set_attempt_limit] = useState(1);
    const [default_score, set_default_score] = useState(1);
    const [cbt_duration, set_cbt_duration] = useState("");
    const [max_question, set_max_question] = useState("");
    const [random_question, set_random_question] = useState("no");
    const [cbtdata, setcbtdata] = useState([]);
    const [duration_style, set_duration_style] = useState(hidden);
    const [max_attempt_style, set_max_attempt_style] = useState(hidden);
    const [score_style, set_score_style] = useState(hidden);
    const [cbt_status, set_cbt_status] = useState('draft');
    const datalist = useRef(null);


    useEffect(()=>{

        axios.get(CbtDataIdGetCbt, ajaxconfig).then((response)=>{
            console.log(response);
            toast(response.data.message);
            setcbtdata(response.data.data);
        }).catch((error)=>{

            toast(error);
            console.log(error);
        });


        // axios.get(GetMassPrivateCourses, ajaxconfig).then((response)=>{
        //     console.log(response);

        // }).catch((error)=>{
        //     console.log(error);
        // });
    }, []);

    const refreshpage = ()=>{

        axios.get(CbtDataIdGetCbt, ajaxconfig).then((response)=>{
            console.log(response);
            toast(response.data.message);
            setcbtdata(response.data.data);
        }).catch((error)=>{

            toast(error);
            console.log(error);
        });

    }


    const submit = (e)=>{
        const data = {
            'cbt_name' : cbt_name_f,
            'attempt_limit' : attempt_limit,
            'default_score' : default_score,
            'cbt_duration'  : cbt_duration,
            'max_question'  : max_question,
            'random_question_mode' : random_question,   
            'cbt_status' : cbt_status,
        }
        e.preventDefault();
        // run validation 

        // send request to the server
        axios.post(CbtDataIdCreateCbt, data, ajaxconfig).then((response)=>{
            console.log(response);
            setcbtdata(response.data.data);
            toast(response.data.message);
        }).catch((error)=>{
            toast(error);
            console.log(error);
        });

    }
    return(
    <>

        <d-section className="main-container">
    <side className="side-close" id="side-menu">
       
       <SideMenu />

    </side>
   
        <main className="main-close" id="main">
                <div className="header-container" style={{width: '100%'}} >     
                     <TopMenu />
                </div>
           
<form className="card static"  onSubmit={submit} style={{marginTop: '5%', display: 'flex', flexWrap: 'wrap'}}>




<div className="card w-75 static mt-2 mb-3 mx-auto">
        <div className="card-header">
            <h6 className="text-center">Config</h6>
        </div>
        
        <div className="card-body">
            

          
            <div className="form-group-2 mt-2" >
                <b>Duration:</b>
               <div style={{display: 'flex'}}>
                <label style={{display: 'flex'}}>
                        <b> No</b>
                        <input onClick={()=>set_duration_style(hidden)} type="radio" className="radio" name="duration_type" /> 
                    </label>
                    <label style={{display: 'flex'}}>
                        <b>Yes</b>
                        <input onClick={()=>set_duration_style(visible)} type="radio" className="radio" name="duration_type" /> 
                    </label>
               </div>

            </div>
            

            <div className="form-group-2 mt-2" >
                <b>Attempt limit:</b>
                <div style={{display: 'flex'}}>
                    <label style={{display: 'flex'}}>
                        <b> No</b>
                        <input onClick={()=>set_max_attempt_style(hidden)} type="radio" className="radio" name="attempt_limit" /> 
                    </label>
                    <label style={{display: 'flex'}}>
                        <b>Yes</b>
                        <input onClick={()=>set_max_attempt_style(visible)} type="radio" className="radio" name="attempt_limit" /> 
                    </label>
                </div>
                
            </div>


            <div className="form-group-2 mt-2" >
                <b>custom score:</b>
               <div style={{display: 'flex'}}>
                <label style={{display: 'flex'}}>
                        <b> No</b>
                        <input onClick={()=>set_score_style(hidden)} type="radio" className="radio" name="custom_score" /> 
                    </label>
                    <label style={{display: 'flex'}}>
                        <b>Yes</b>
                        <input onClick={()=>set_score_style(visible)} type="radio" className="radio" name="custom_score" /> 
                    </label>
               </div>

            </div>


            <div className="form-group-2 mt-2" >
                <b>CBT status:</b>
               <div style={{display: 'flex'}}>
                <label style={{display: 'flex'}}>
                        <b> Published</b>
                        <input onClick={()=>set_cbt_status('published')} type="radio" className="radio" name="cbt_status" /> 
                    </label>
                    <label style={{display: 'flex'}}>
                        <b>Draft</b>
                        <input onClick={()=>set_cbt_status('draft')} type="radio" className="radio" name="cbt_status" /> 
                    </label>
               </div>

            </div>
            
        {/* laste */}
        </div>
</div> 


<div className="card static w-75 mx-auto" style={{padding: '10px'}}>

                <div className="form-group-2 mt-2">

                    <label>
                    <h6>CBT Name</h6>
                        <input 
                    value={cbt_name_f} onChange={(e)=>set_cbt_name(e.target.value)} placeholder="CBT name" 
                    /> </label>
                </div>

                

            <div className="form-group-2 mt-2" style={max_attempt_style} >

                <label>
                    <h6>Max Attempt</h6>
                    <input input type="number"
                value={attempt_limit} onChange={(e)=>set_attempt_limit(e.target.value)} placeholder="Attempt limit" 
                /> </label>
            </div>

            <div className="form-group-2 mt-2" style={score_style}>

                <label>
                    <h6>Default score</h6>
                    <input input type="number"
                value={default_score} onChange={(e)=>set_default_score(e.target.value)} placeholder="Default score" 
                /> </label>
            </div>
            <div className="form-group-2 mt-2" style={duration_style} >
                <label>
                <h6>Duration in minutes:</h6>
                    <input type="number"
                value={cbt_duration} onChange={(e)=>set_cbt_duration(e.target.value)} placeholder="CBT duration" 
                /> </label>
            </div>

                <div className="form-group-2 mt-1 mb-2">
                    <button className="btn btn-danger w-100" >Create CBT </button>
                </div>
                <button_loader button_effect={visible} />
        
</div>

</form>   

<div className=" mt-2 mb-3" >
<div className="card static w-100 mx-auto">
        <table className="table mt-2 mb-3">
        <thead>
            <tr>
                <th>CBT</th>
                <th>Date</th>
                <th>Action</th>
            </tr>
        </thead>  
        <tbody>
            {
                cbtdata.map((data)=>(
                    <Cbtdata_list refreshpage={refreshpage} datalist={data} />
                ))
            }
  
        </tbody>      
    </table>

</div>
</div>


<ToastContainer />




      </main>
</d-section>
    </>
    );

}






const CbtAnswerEdit = ()=>{

    const [cbt_data_validate, set_cbt_data_validate] = useState(false);
    const [answer, set_answer] = useState([]);

    // db question 
    const [db_questions, set_db_questions] = useState("");
    const [current_option, set_current_option] = useState("");
    const [current_option_id, set_current_option_id] = useState("");
    const [cbt_question_id, set_cbt_question_id] = useState("");

    // create answer
    const [option_status, set_option_status] = useState("options");
    const [custom_mark_status, set_custom_mark_status] = useState("false");
    const [custom_mark, set_custom_mark] = useState("");
    // get the url parameter
    const url_param = new URLSearchParams(window.location.search);
    const cbt_data_passcode = url_param.get("cbt_data_id"); 
    const cbt_question_passcode = url_param.get("cbt_question_id"); 
    const cbt_answer_route = url_param.get("cbt_answer_id");
    // validate cbt data id

    useEffect(() =>{

        // validating cbt data id
        const url = CbtDataIdPrivateCbtDataVerify+cbt_data_passcode;
        axios.get(url, ajaxconfig).then((response)=>{
            console.log(response);
            const cbt_data_id = response.data.data.cbt_data_id;

            toast(response.data.message);

        
        }).catch((error)=>{
            toast(error);
            console.log(error);
        });


        // fetching already created single question all answers
        const single_question_url = SinglePrivateQuestionGet+"/"+cbt_data_passcode+"/"+cbt_question_passcode;

        axios.get(single_question_url, ajaxconfig).then((response)=>{

            const single_question_db_fetch = response.data.cbt_question;
            if(!single_question_db_fetch){
                
                toast(response.data.message);
            }
            console.log(response);


                set_cbt_question_id(response.data.cbt_question_id);
                set_db_questions(single_question_db_fetch);
                set_answer(response.data.cbt_answer);
               
        }).catch((error)=>{


            console.log(error);
        });

        // edit answer reequest
        const editurl = SinglePrivateAnswerGet+cbt_data_passcode+"/"+cbt_question_passcode+"/"+cbt_answer_route;
        axios.get(editurl, ajaxconfig).then((response)=>{
            console.log(response);
            set_current_option(response.data.data.options);
            set_current_option_id(response.data.data.answer_id);
            set_option_status(response.data.data.option_status);
            set_custom_mark_status(response.data.data.custom_mark_status); 
            set_custom_mark(response.data.data.custom_mark);
        }).catch((error)=>{
            console.log(error);
            console.log("am not working");
            toast(error);

        });
    }, []
);

const Delete =  async () => {
    // if (await confirm({
    //   confirmation: 'Are you sure?'
    // })) {
    //   console.log('yes');
    // } else {
    //   console.log('no');
    // }
  }
    // instant updating 
    
    // updating previous answer with the id 
    const answersubmit = (event)=>{
        event.preventDefault();

        // data
        const submitanswer = {
            option : current_option,
            option_status: option_status, 
            custom_mark_status : custom_mark_status, 
            custom_mark : custom_mark,
            cbt_question_id: cbt_question_passcode,
            cbt_data_id : cbt_data_passcode,
            answer_id: current_option_id
        }

        console.log(submitanswer);
        axios.post(CreateCbtQuestionAnswer, submitanswer, ajaxconfig).then((response)=>{
            console.log(response);
            toast(response.data.message);
        }).catch((error)=>{
            toast(error);
            console.log(error);
        });
    } 


return(
    <>
  
        <d-section className="main-container">
    <side className="side-close" id="side-menu">
       
       <SideMenu />

    </side>
   
        <main className="main-close" id="main">
                <div className="header-container" style={{width: '100%'}} >
                    <TopMenu />
                </div>

<div className="custom-card mt-2" >
<div className="card w-100 static" style={{marginTop: '7%'}}>
            <form onSubmit={answersubmit} >
                <div className="form-group">
                    <h4 className="text-center">Question</h4>
                    <p className='text-justify'>{db_questions}</p>
                </div>
                <h4 className="text-center">Edit option</h4>
                <div className="form-group">
                    <label>
                        <h6>Option</h6>
                        <textarea rows="10" value={current_option} onChange={(e)=>set_current_option(e.target.value)} />
                    </label>
                </div>
                <div className="form-group">
                    <label>
                        <h6>Option Status</h6>
                        <select value={option_status} onChange={(e)=>set_option_status(e.target.value)} >
                            <option>options</option>
                            <option>correct</option>
                        </select>
                    </label>
                </div>
                <div className="form-group mb-3">
                    <button className="btn btn-danger w-100">Edit option</button>
                </div>
            </form>    
</div>            
</div>

<div className="custom-card mt-2" >
<div className="card w-100 static">
    <div className="card-header">
        <h4 className="text-center"><b>Options</b></h4>
    </div>
    <div className="card-body">
        <table className="table">
                <thead>
                    <tr>
                    <th>#</th>
                    <th>CBT Option</th>
                    <th>Option Status</th>
                    <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        answer.map((answer)=>(
                        <tr key={answer.id}>
                            <td>{answer.id}</td>
                            <td>{answer.options}</td>
                            <td>{answer.option_status}</td>
                            <td className="d-flex"><a href={"/cbt_creator/cbt_question_edit_answer?cbt_data_id="+cbt_data_passcode + "&cbt_question_id=" + cbt_question_id + "&cbt_answer_id="+answer.answer_id} ><button className="btn btn-danger">Edit Option</button></a><button onClick={Delete} className="btn btn-danger mr-2">Delete</button></td>
                        </tr>
                        ))
                    }
                </tbody>
            </table>

    </div>
</div>
</div>
      </main>
</d-section>
    </>
    );
}


const CbtQuestionEdit = ()=>{

    const hidden = {
        display: 'none'
    };

    const visible = {
        visibility: 'block'
    };



    const [score, set_score] = useState(1);
    const [score_setting, set_score_setting] = useState("false");
    const [cbt_data_validate, set_cbt_data_validate] = useState(false);
    const [question_type_id, set_question_type_id] = useState("objectives");
    const [sort, set_sort] = useState(1);

    // cbt config
    const [question_status, set_question_status] = useState("draft");
    const [multi_choice, set_multi_choice] = useState("no");
    const [question_type, set_question_type] = useState("Q & A");
    const [display_sort, set_display_sort] = useState("true");
    const [score_style, set_score_style] = useState(hidden);
    const [sort_style, set_sort_style] = useState(hidden);

    const [answer, set_answer] = useState([]);

    // db question 
    const [db_questions, set_db_questions] = useState("");
    const [cbt_question_id, set_cbt_question_id] = useState("");

    // create answer
    const [option, set_option] = useState("");
    const [option_status, set_option_status] = useState("options");
    const [custom_mark_status, set_custom_mark_status] = useState("false");
    const [custom_mark, set_custom_mark] = useState("");
    // get the url parameter
    const url_param = new URLSearchParams(window.location.search);
    const cbt_data_passcode = url_param.get("cbt_data_id"); 
    const cbt_question_passcode = url_param.get("cbt_question_id"); 
    
    // validate cbt data id

    useEffect(() =>{

        // validating cbt data id
        const url = CbtDataIdPrivateCbtDataVerify+cbt_data_passcode;
        axios.get(url, ajaxconfig).then((response)=>{
            console.log(response);
            const cbt_data_id = response.data.data.cbt_data_id;

        
        }).catch((error)=>{
            toast(error);
            console.log(error);
        });


        // fetching already created single question all answers
        const single_question_url = SinglePrivateQuestionGet+"/"+cbt_data_passcode+"/"+cbt_question_passcode;

        axios.get(single_question_url, ajaxconfig).then((response)=>{

            const single_question_db_fetch = response.data.cbt_question;
            
            console.log(response);
            if(!single_question_db_fetch){
                toast("an error occur");
            }
            if(single_question_db_fetch){
                set_db_questions(single_question_db_fetch);
                set_answer(response.data.cbt_answer);
                set_cbt_question_id(response.data.cbt_question_id)
            }
        }).catch((error)=>{
            toast(error);
            console.log(error);
        });
    }, []
)
   
 
    // submit question 
    const submit = (event)=>{

        event.preventDefault();
        const data = {
            question : db_questions,
            cbt_data_id : cbt_data_passcode,
            score : score,
            question_type_id: question_type_id

        }
        axios.post(CbtQuestionCreate, data, ajaxconfig).then((response)=>{
            console.log(response);
            toast(response.data.message);
        }).catch((error)=>{
            toast(error);
            console.log(error);
        });
    }

    // creating new answers 
    const answersubmit = (event)=>{
        event.preventDefault();

        // data
        const submitanswer = {
            option : option,
            option_status: option_status, 
            custom_mark_status : custom_mark_status, 
            custom_mark : custom_mark,
            cbt_question_id: cbt_question_passcode,
            cbt_data_id : cbt_data_passcode
        }

        // console.log(submitanswer);
        axios.post(CreateCbtQuestionAnswer, submitanswer, ajaxconfig).then((response)=>{
            console.log(response);
            toast(response.data.message);
        }).catch((error)=>{
            toast(error);
            console.log(error);
        });
    }

    return(
        <>

            <d-section className="main-container">
        <side className="side-close" id="side-menu">
           
           <SideMenu />
    
        </side>
       
            <main className="main-close" id="main" >
                 <div className="header-container" style={{width: '100%'}} >        
                    <TopMenu />
                </div>
    

<form  className="custom-card" style={{marginTop: '7%', display: 'flex'}} onSubmit={submit} >
  
    
  <div className="card w-25 static mt-2 mb-3">
          <div className="card-header">
              <h6 className="text-center">Config</h6>
          </div>
          
          <div className="card-body">
              
  
            
              <div className="form-group-2 mt-2" >
                  <b>Multi choice:</b>
                 <div style={{display: 'flex'}}>
                  <label style={{display: 'flex'}}>
                          <b> No</b>
                          <input onClick={()=>set_multi_choice("no")} type="radio" className="radio" name="multi_choice" /> 
                      </label>
                      <label style={{display: 'flex'}}>
                          <b>Yes</b>
                          <input onClick={()=>set_multi_choice("yes")} type="radio" className="radio" name="multi_choice" /> 
                      </label>
                 </div>
  
              </div>
              
  
              <div className="form-group-2 mt-2" >
                  <b>Question type:</b>
                  <div style={{display: 'flex'}}>
                      <label style={{display: 'flex'}}>
                          <b> Q & A</b>
                          <input onClick={()=>set_question_type("Q & A")} type="radio" className="radio" name="question_type" /> 
                      </label>
                      <label style={{display: 'flex'}}>
                          <b>feedback</b>
                          <input onClick={()=>set_question_type("feedback")} type="radio" className="radio" name="question_type" /> 
                      </label>
                  </div>
                  
              </div>
  
  
              <div className="form-group-2 mt-2" >
                  <b>score:</b>
                 <div style={{display: 'flex'}}>
                  <label style={{display: 'flex'}}>
                          <b> No</b>
                          <input onClick={()=>set_score_style(hidden)} type="radio" className="radio" name="custom_score" /> 
                      </label>
                      <label style={{display: 'flex'}}>
                          <b>Yes</b>
                          <input  onClick={()=>set_score_style(visible)} type="radio" className="radio" name="custom_score" /> 
                      </label>
                 </div>
  
              </div>
  
  
              <div className="form-group-2 mt-2" >
                  <b>score setting:</b>
                 <div style={{display: 'flex'}}>
                  <label style={{display: 'flex'}}>
                          <b> No</b>
                          <input onClick={()=>set_score_setting("false")} type="radio" className="radio" name="custom_score" /> 
                      </label>
                      <label style={{display: 'flex'}}>
                          <b>Yes</b>
                          <input  onClick={()=>set_score_setting("true")} type="radio" className="radio" name="custom_score" /> 
                      </label>
                 </div>
  
              </div>
  
  
              <div className="form-group-2 mt-2" >
                  <b>Display sort:</b>
                 <div style={{display: 'flex'}}>
                  <label style={{display: 'flex'}}>
                          <b> Yes</b>
                          <input onClick={()=>set_display_sort('true')} type="radio" className="radio" name="cbt_status" /> 
                      </label>
                      <label style={{display: 'flex'}}>
                          <b>No</b>
                          <input onClick={()=>set_display_sort('false')} type="radio" className="radio" name="cbt_status" /> 
                      </label>
                 </div>
  
              </div>
  
              <div className="form-group-2 mt-2" >
                  <b>sort:</b>
                 <div style={{display: 'flex'}}>
                  <label style={{display: 'flex'}}>
                          <b> No</b>
                          <input onClick={()=>set_sort_style(hidden)} type="radio" className="radio" name="custom_score" /> 
                      </label>
                      <label style={{display: 'flex'}}>
                          <b>Yes</b>
                          <input  onClick={()=>set_sort_style(visible)} type="radio" className="radio" name="custom_score" /> 
                      </label>
                 </div>
  
              </div>
  
  
              <div className="form-group-2 mt-2" >
                  <b>Question status:</b>
                 <div style={{display: 'flex'}}>
                  <label style={{display: 'flex'}}>
                          <b> Published</b>
                          <input onClick={()=>set_question_status('published')} type="radio" className="radio" name="cbt_status" /> 
                      </label>
                      <label style={{display: 'flex'}}>
                          <b>Draft</b>
                          <input onClick={()=>set_question_status('draft')} type="radio" className="radio" name="cbt_status" /> 
                      </label>
                 </div>
  
              </div>
              
          {/* laste */}
          </div>
  </div> 
  
  
  
      <div className="card static w-75 card-size" >
  
          <div className="card-body">
                  <div className="form-group-2 mt-2">
                  
                      <label>
                      <textarea placeholder="please type question..." rows="10" value={db_questions} onChange={(e)=>set_db_questions(e.target.value)} />
                      </label>
                  </div>
                  
              
                  <div className="form-group-2 mt-2" style={score_style}>
                  
                  <label>
                      <h6>custom score</h6>
                      <input type="number" value={score} onChange={(e)=>set_score(e.target.value)} />
                  </label>
                  </div>
  
  
                  <div className="form-group-2 mt-2" style={sort_style}>
                  
                  <label>
                      <h6>sort</h6>
                      <input type="number" value={sort} onChange={(e)=>set_sort(e.target.value)} />
                  </label>
                  </div>
  
                  <div className="form-group-2 mt-1 mb-2">
                      <button className="btn btn-danger w-100" >Update Question </button>
                  </div>
          </div>          
      </div>         
      
  </form>
  

<div className="custom-card mt-2" >
<div className="card static mt-5 w-100 mx-auto">
        <div className="card-header">
            <h4 className="text-center"><b>Add Options</b></h4>
        </div>
        <div className="card-body">
                <form class="mx-auto w-75" onSubmit={answersubmit} >
                    <div className="form-group">
                        <label>
                            <h6>Option</h6>
                            <textarea placeholder="please type options" rows="10" value={option} onChange={(e)=>set_option(e.target.value)} />
                        </label>
                    </div>
                    <div className="form-group">
                        <label>
                            <h6>Option Status</h6>
                            <select value={option_status} onChange={(e)=>set_option_status(e.target.value)}>
                                <option>options</option>
                                <option>correct</option>
                            </select>
                        </label>
                    </div>
                    <div className="form-group mb-3">
                        <button className="btn btn-danger w-100">Add option</button>
                    </div>
                </form>

        </div>

</div>
</div>


<div className="custom-card mt-2" >
<div className='card mt-2 mx-auto w-100 static'>

        <div className="card-header">
            <h4 className="text-center"><b>Options</b></h4>
        </div>
        <div className="card-body">
            <table className="table">
                    <thead>
                        <tr>
                        <th>#</th>
                        <th>Option</th>
                        <th>Options Type</th>
                        <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            answer.map((answer)=>(
                            <tr key={answer.id}>
                                <td>{answer.id}</td>
                                <td>{answer.options}</td>
                                <td>{answer.option_status}</td>
                                <td className="d-flex">
                                    <a href={"/cbt_creator/cbt_question_edit_answer?cbt_data_id="+cbt_data_passcode + "&cbt_question_id=" + cbt_question_id + "&cbt_answer_id="+answer.answer_id} >
                                        <button className="btn btn-danger">Edit Option</button>
                                    </a>
                                        <button className="btn btn-danger mr-2">Delete</button></td>
                            </tr>
                            ))
                        }
                    </tbody>
            </table>

        </div>

</div>
</div>
          </main>
    </d-section>
        </>
        );
}



const  CbtData = ()=>{

    const hidden = {
        display: 'none'
    };

    const visible = {
        visibility: 'block'
    };


    const [question, setquestion] = useState("");

    const [score, set_score] = useState(1);
    const [score_setting, set_score_setting] = useState("false");
    const [cbt_data_validate, set_cbt_data_validate] = useState(false);
    const [question_type_id, set_question_type_id] = useState("objectives");
    const [sort, set_sort] = useState(1);

    // db question 
    const [db_questions, set_db_questions] = useState([]);
    // get the url parameter
    const url_param = new URLSearchParams(window.location.search);
    const cbt_data_passcode = url_param.get("cbt_data_id"); 

    // cbt config
    const [question_status, set_question_status] = useState("draft");
    const [multi_choice, set_multi_choice] = useState("false");
    const [question_type, set_question_type] = useState("Q & A");
    const [display_sort, set_display_sort] = useState("true");
    const [score_style, set_score_style] = useState(hidden);
    const [sort_style, set_sort_style] = useState(hidden);


    
    // validate cbt data id

    useEffect(() =>{

        // validating cbt data id
        const url = CbtDataIdPrivateCbtDataVerify+cbt_data_passcode;
        axios.get(url, ajaxconfig).then((response)=>{
            console.log(response);
            const cbt_data_id = response.data.data.cbt_data_id;

            if(!cbt_data_id){

            }

        
        }).catch((error)=>{

            console.log(error);
        });


        // fetching already created questions 
        const mass_question_url = MassPrivateQuestionGet+cbt_data_passcode;

        axios.get(mass_question_url, ajaxconfig).then((response)=>{

            const mass_question_db_fetch = response.data.data;
            if(!response.data.data){

            }
            if(mass_question_db_fetch){
                set_db_questions(mass_question_db_fetch);
            }
        }).catch((error)=>{

        });
    }, []
)
   
 
    // submit question 
    const submit = (event)=>{

        event.preventDefault();
        const data = {
            question : question,
            cbt_data_id : cbt_data_passcode,
            score : score,
            score_setting: score_setting,
            question_type_id: question_type_id,
            question_type : question_type,
            sort: sort,
            display_sort : display_sort

        }
        axios.post(CbtQuestionCreate, data, ajaxconfig).then((response)=>{
            console.log(response);
            toast(response.data.message);
        }).catch((error)=>{
            toast(error);
            console.log(error);
        });
    }
  
    return(
        <>
            <d-section className="main-container">
        <side className="side-close" id="side-menu">
           
           <SideMenu />
    
        </side>
       
            <main className="main-close" id="main" >
                <div className="header-container" style={{width: '100%'}} >
                    <TopMenu />
                </div>
    
    <form  className="" style={{marginTop: '7%'}} onSubmit={submit} >
  
    
<div className="card w-100 static mt-2 mb-3">
        <div className="card-header">
            <h6 className="text-center">Config</h6>
        </div>
        
        <div className="card-body">
            

          
            <div className="form-group-2 mt-2" >
                <b>Multi choice:</b>
               <div style={{display: 'flex'}}>
                <label style={{display: 'flex'}}>
                        <b> No</b>
                        <input onClick={()=>set_multi_choice("no")} type="radio" className="radio" name="multi_choice" /> 
                    </label>
                    <label style={{display: 'flex'}}>
                        <b>Yes</b>
                        <input onClick={()=>set_multi_choice("true")} type="radio" className="radio" name="multi_choice" /> 
                    </label>
               </div>

            </div>
            

            <div className="form-group-2 mt-2" >
                <b>Question type:</b>
                <div style={{display: 'flex'}}>
                    <label style={{display: 'flex'}}>
                        <b> Q & A</b>
                        <input onClick={()=>set_question_type("Q & A")} type="radio" className="radio" name="question_type" /> 
                    </label>
                    <label style={{display: 'flex'}}>
                        <b>feedback</b>
                        <input onClick={()=>set_question_type("feedback")} type="radio" className="radio" name="question_type" /> 
                    </label>
                </div>
                
            </div>


            <div className="form-group-2 mt-2" >
                <b>score:</b>
               <div style={{display: 'flex'}}>
                <label style={{display: 'flex'}}>
                        <b> No</b>
                        <input onClick={()=>set_score_style(hidden)} type="radio" className="radio" name="custom_score" /> 
                    </label>
                    <label style={{display: 'flex'}}>
                        <b>Yes</b>
                        <input  onClick={()=>set_score_style(visible)} type="radio" className="radio" name="custom_score" /> 
                    </label>
               </div>

            </div>


            <div className="form-group-2 mt-2" >
                <b>score setting:</b>
               <div style={{display: 'flex'}}>
                <label style={{display: 'flex'}}>
                        <b> No</b>
                        <input onClick={()=>set_score_setting("false")} type="radio" className="radio" name="custom_scor" /> 
                    </label>
                    <label style={{display: 'flex'}}>
                        <b>Yes</b>
                        <input  onClick={()=>set_score_setting("true")} type="radio" className="radio" name="custom_scor" /> 
                    </label>
               </div>

            </div>


            <div className="form-group-2 mt-2" >
                <b>Display sort:</b>
               <div style={{display: 'flex'}}>
                <label style={{display: 'flex'}}>
                        <b> Yes</b>
                        <input onClick={()=>set_display_sort('true')} type="radio" className="radio" name="cbt_status" /> 
                    </label>
                    <label style={{display: 'flex'}}>
                        <b>No</b>
                        <input onClick={()=>set_display_sort('false')} type="radio" className="radio" name="cbt_status" /> 
                    </label>
               </div>

            </div>

            <div className="form-group-2 mt-2" >
                <b>sort:</b>
               <div style={{display: 'flex'}}>
                <label style={{display: 'flex'}}>
                        <b> No</b>
                        <input onClick={()=>set_sort_style(hidden)} type="radio" className="radio" name="custom_score" /> 
                    </label>
                    <label style={{display: 'flex'}}>
                        <b>Yes</b>
                        <input  onClick={()=>set_sort_style(visible)} type="radio" className="radio" name="custom_score" /> 
                    </label>
               </div>

            </div>


            <div className="form-group-2 mt-2" >
                <b>Question status:</b>
               <div style={{display: 'flex'}}>
                <label style={{display: 'flex'}}>
                        <b> Published</b>
                        <input onClick={()=>set_question_status('published')} type="radio" className="radio" name="cbt_status" /> 
                    </label>
                    <label style={{display: 'flex'}}>
                        <b>Draft</b>
                        <input onClick={()=>set_question_status('draft')} type="radio" className="radio" name="cbt_status" /> 
                    </label>
               </div>

            </div>
            
        {/* laste */}
        </div>
</div> 



    <div className="card static w-100" >

        <div className="card-body">
                <div className="form-group-2 mt-2">
                
                    <label>
                    <textarea placeholder="please type question..." rows="10" value={question} onChange={(e)=>setquestion(e.target.value)} />
                    </label>
                </div>
                
            
                <div className="form-group-2 mt-2" style={score_style}>
                
                <label>
                    <h6>custom score</h6>
                    <input type="number" value={score} onChange={(e)=>set_score(e.target.value)} />
                </label>
                </div>


                <div className="form-group-2 mt-2" style={sort_style}>
                
                <label>
                    <h6>sort</h6>
                    <input type="number" value={sort} onChange={(e)=>set_sort(e.target.value)} />
                </label>
                </div>

                <div className="form-group-2 mt-1 mb-2">
                    <button className="btn btn-danger w-100" >Create Question </button>
                </div>
        </div>          
    </div>         
    
</form>

<div className="custom-card mt-2" >
<div className="card w-100 mt-3 mx-auto static">
                    <div className="card-header mt-2">
                        <h6 className="text-center">Created Question Numbers</h6>
                    </div>

            {/* card body */}
            <div className="card-body mt-2">

                        <div className="d-flex flex-wrap mx-auto">
                    {
                        db_questions.map((question)=>(
                            <a className="mt-2 mb-2" style={{marginLeft: '3%'}} href={"/cbt_creator/cbt_question_edit?cbt_data_id="+cbt_data_passcode + "&cbt_question_id=" + question.cbt_question_id} ><button className="btn btn-danger">{question.sort}</button></a>
                        ))
                    }
                </div>


                    
            </div>
            {/* card body */}

</div>
</div>


<div className="custom-card mt-2" >
<div className="card mt-2 mb-5 mx-auto static">

            <table className="table mt-2">
                    <thead>
                        <tr>
                        <th>S/N</th>
                        <th>Question</th>
                        <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            db_questions.map((question)=>(
                            <tr key={question.id}>
                                <td>{question.sort}</td>
                                <td>{question.cbt_question}</td>
                                <td className="d-flex"><a href={"/cbt_creator/cbt_question_edit?cbt_data_id="+cbt_data_passcode + "&cbt_question_id=" + question.cbt_question_id} ><button className="btn btn-danger">Edit Question</button></a><button className="btn btn-danger mr-2">Delete</button></td>
                            </tr>
                            ))
                        }
                    </tbody>
                </table>

</div>
</div>
          </main>
    </d-section>
        </>
        );
    
}


export  {Cbt,  CbtQuestionEdit, CbtAnswerEdit, CbtData};
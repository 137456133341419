import React, { useEffect } from 'react';
import {useState} from 'react';
import {Footer} from "./footer";
import { Header } from './header';
import axios from 'axios';
import { ajaxconfig } from './ajaxconfig';
import { updateProfile, profileData } from './serverurl';
import { useNavigate  } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
// import 'react-phone-number-input/style.css';
// import  PhoneInput  from 'react-phone-number-input';



function Profile(){

        // active button
        const hidden = {
            visibility: 'hidden'
        };
    
        const visible = {
            visibility: 'visible'
        };
    

    const [button_active, set_button_active] = useState(visible);
    const [button_effect, set_button_effect] = useState(hidden);

    const [password, setPassword] = useState("");
    const [name, setName] = useState("");
    const [whatSapp, setwhatSapp] = useState();
    const [disabled_status, set_disabled_status] = useState("none");
    
    const navigate = useNavigate();
    
           
            let profile_form = (e)=>{
                e.preventDefault();
                // toast("Wow so easy!");

                // button effects
                set_button_active(hidden);
                set_button_effect(visible);

                set_disabled_status("disabled");
                const data = {
                    whatsapp: whatSapp,
                    password: password
                }

             
        //  ajax request
        axios.post(updateProfile, data, ajaxconfig).then((response)=>{
            // console.log(response);

            set_button_active(visible);
            set_button_effect(hidden);

            if(response.status !== 200){
                toast("Please try again....");
                set_disabled_status("none");

            }
            if(response.status === 200){

                if(response.data.serverstatus !== 401){
                    toast(response.data.message);
                    setPassword(response.data.password);
                    setName(response.data.data.name);
                    setwhatSapp(response.data.data.phone_number);
                    set_button_active(visible);
                    set_button_effect(hidden);
        
                    navigate("/cbt_creator/dashboard");
                }else{
                    // restrict user from login
                    toast(response.data.message);
                    set_disabled_status("none");


                    set_button_active(visible);
                    set_button_effect(hidden);
        
                }
                
            }
            
            
        }).catch((error)=>{ 
            toast("please try again...");
            console.log(error);
            set_disabled_status("none");

            set_button_active(visible);
            set_button_effect(hidden);


         });
        
        }    
    
        useEffect(()=>{

            axios.get(profileData, ajaxconfig).then((response)=>{

                console.log(response);
                setPassword(response.data.data.password);
                setName(response.data.data.name);
                setwhatSapp(response.data.data.phone_number);
            }).then((error)=>{

                console.log(error);
            })
        }, []);

   
    return(
        <>

        <Header />
               
                <div className="container default-margin-top" >
                    {/*  */}
                    <div className="card mx-auto static card-size">
                 <h3 className="text-center"><a className="nav-link" href="https://cbtdraft.com" ><b>Profile</b></a></h3>
                 <div className="form-group mt-1 mx-auto">
            

        </div>
                 <form onSubmit={profile_form} style={{ width: '85%', borderRadius: '5%'}} className="mx-auto" >
                        <div className="form-group">
                           <input type="text" value={name} onChange={(e)=>setName(e.target.value)} placeholder="Name" name="name" />
                       </div>

                       <div className="form-group mt-3">
                           <input type="text" value={whatSapp} onChange={(e)=>setwhatSapp(e.target.value)} placeholder="Whatsapp No" name="name" />
                       </div>
                       
                       <div className="form-group mt-3">
                           <input type="password" value={password} onChange={(e)=>setPassword(e.target.value)} placeholder="Password" name="password" />
                       </div>
                       <div className="form-group mt-1 mb-1" style={button_active}>
                           <button className="btn btn-danger w-100">Save & Continue</button>
                       </div>


                       <div className="form-group mt-1 mb-1" style={button_effect} >
                           <button className="btn btn-danger w-100" disabled><span className="spinner-border text-primary" /></button>
                       </div>


                   </form>
          
                                <ToastContainer />
                </div>
                    {/*  */}
                </div>
                
            

            
<div className="footer card" >
    <Footer />
</div>

        </>

    );
}




export  default Profile;


import { Header } from "./header";
import { Footer } from "./footer";
import { Helmet } from 'react-helmet-async';


const ContactUs = ()=>{

    return(<>
            <Helmet>
        <meta id="description" name="discription" content="cbtdraft contact us" />
        <title id="title">cbtdraft.com | contact us</title>
      </Helmet>
<Header />
<div className="container  default-margin-top" >
    <div className="card mt-5 static">

        <h3 className="text-center mt-3">Contact US</h3>
        <div className="card-body">
        <form className='mx-auto' >
            <div className="form-group mt-2">
                <label><input type="email" placeholder='Email' /></label>
            </div>
            <div className='form-group mt-2'>
                <label>
                    <textarea rows={'10'} placeholder='Message' />
                </label>
            </div>
            <div className='form-group mt-3'>
                <button className="form-control btn btn-danger">Contact Us</button>
            </div>
        </form>
        <h4 className="mt-3">Email: moyeso@cbtdraft.com</h4>
        <h4 className="mt-1">Text/Whatsapp: +2349039421996, +2348113315252</h4>

        </div>
    </div>

</div>    

    <div className="footer card" >
<Footer />
</div>
    </>)
}


export {
    ContactUs
}
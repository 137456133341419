const hidden = {
    display: 'none'
};

const visible = {
    visibility: 'block'
};



const button_loader = (props)=>{

    return(<>
    <div className="form-group mt-1 mb-1" style={props.button_effect} >
                           <button className="btn btn-danger w-100" disabled><span className="spinner-border spinner-border-sm text-primary" /></button>
                       </div>
    </>)
}

export {
    hidden, 
    visible,
    button_loader
};